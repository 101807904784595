import React from "react"
import Container from "../components/container"
import SEO from "../components/seo"

import {Timeline} from 'antd';
import TimelineItem from "antd/es/timeline/TimelineItem";

const AboutTeikojutsu = () => (
    <Container defKey="0">
        <SEO title="Über Teikojutsu"/>
        <div style={{padding: 50}}>
            <h1>Über Teikojutsu</h1>

            <Timeline mode={"alternate"}>
                <TimelineItem>
                    <h3>Wie alles im Jahre 2007 begann</h3>

                    Am Anfang standen die Selbstverteidigungs-Workshops von Arne Kappellusch im Vordergrund.
                    Arne machte dabei viele unterschiedliche Erfahrungen mit verschiedenen Kampfkünsten unter
                    der Bezeichnung „Chian Do“.<br/>
                    Chian Do umfasste neben der Selbstbehauptung auch die verbale und nonverbale Deeskalation
                    bei kritischen, möglicherweise gefährlichen Konflikten mit anderen , wenn z. B. eine
                    gewaltsame Auseinandersetzung akut drohte. In dem Fall sollte als letztes Mittel die
                    praktische Selbstverteidigung mit Kampfsporttechniken angewandt werden.
                    <br/>
                    Im Jahre 2008 kam es zu einer Weiterentwicklung des Chian Do, als durch Christoph
                    Greifenhain in Absprache mit dem Hamburger Universitäts-Institut für Japanistik der Begriff
                    „Teikojutsu“ ins Leben gerufen wurde. Und so entstand im Laufe der Zeit eine neue
                    Kampfsportart.
                </TimelineItem>
                <TimelineItem>
                    <h3>Ein System ensteht</h3>
                    Anfang 2009 kam Olaf Laur mit seiner mehr als 30-jährigen Kampfsporterfahrung dazu und
                    erarbeitete zusammen mit Arne Kappellusch die erste, jetzt noch bestehende Prüfungsordnung,
                    mit welcher eine grundlegende Philosophie und Struktur in das System gebracht wurde. Diese
                    Prüfungsordnung wird inzwischen auch von vielen anderen Großmeistern akzeptiert. Dabei war
                    es bezeichnend, dass aus der bunten Vielzahl anderer Kampfsporttechniken die effektivsten für
                    das neue Teikojutsu herausgefiltert wurden. Die entsprechenden Kampfsportarten davon
                    werden später noch einmal etwas genauer angesprochen.
                </TimelineItem>
                <TimelineItem>
                    <h3>Teikojutsu heute</h3>
                    Zu den aktiven Lehrmeistern gehören:
                    <br/>
                    <br/>
                    - Olaf Laur
                    <br/>
                    <br/>
                    - Franziska Laur
                    <br/>
                    <br/>
                    - Hans-Joachim Paulsen <br/>
                    <br/>
                    - Jens Laur
                    <br/>
                </TimelineItem>
            </Timeline>
        </div>
    </Container>
)

export default AboutTeikojutsu
